import {
    Container,
    CssBaseline,
    Divider,
    Grid,
    ThemeProvider,
    Typography,
} from "@mui/material";
import { Contact } from "Contact";
import { HomeImage } from "HomeImage";
import { Library } from "Library";
import { Partners } from "Partners";
import { Helmet } from "react-helmet";
import { theme } from "theme";
import { Align } from "ui/Align";
import { Link } from "ui/Link";
import { Footer } from "../Footer";
import { Header } from "../Header";
import React from "react";

export default function Index() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Helmet
                htmlAttributes={{
                    lang: "en",
                }}
            >
                <meta charSet="utf-8" />
                <title>
                    QPED: Quality-focussed Programming Education: Intellectual
                    Output 1
                </title>
                <meta
                    name="description"
                    content="QPED: Quality-focussed Programming Education - Intellectual Output 1"
                />
                <link rel="canonical" href="https://www.qped.eu" />
            </Helmet>
            <Header />
            <HomeImage title="Intellectual Output 1: TILE" />
            {/* Hero unit */}
            <Container
                id="about"
                maxWidth="md"
                component="main"
                sx={{
                    paddingTop: 8,
                    paddingLeft: 2,
                    paddingRight: 2,
                    paddingBottom: 6,
                }}
            >
                <Typography color="textSecondary" gutterBottom>
                    Integrating software testing early into programming does
                    have many benefits for students. For example, students can
                    easier accept that testing is part of writing software, the
                    design and implementation of software improves, software is
                    easier to test, and it encourages students to more carefully
                    read the assignments. Unfortunately, many drawbacks still
                    exist, resulting in introductory programming courses that
                    are, even today, being taught without testing. For example,
                    the negative attitude of students towards testing, and most
                    programming courses are already fully packed. To help
                    educators to improve this situation, we developed the TILE
                    approach: Test Informed Learning with Examples. It is a new
                    approach to introduce software testing in introductory and
                    advanced programming courses in the following ways:
                    <ul>
                        <li>
                            early introduce students to testing from the very
                            first example program they see and write themselves
                            in exercises;
                        </li>
                        <li>
                            seamless testing will be introduced in a smooth and
                            continuous way as an inherent part of programming,
                            and not as a separate activity;
                        </li>
                        <li>
                            subtly we will make use of clever and indirect
                            methods to teach testing knowledge and skills.
                        </li>
                    </ul>
                    TILE aims to introduce testing in such a subtle way that
                    testing is taught naturally as an inherent part of
                    programming and not a separate activity. TILE examples are
                    related to test runs, test cases, test messages and the
                    alteration of the domain of exercises. These can be
                    gradually be integrated into existing curricula and,
                    consequently, using TILE requires less effort than
                    completely overhauling existing programming courses.
                </Typography>
                <Typography variant="h4" gutterBottom>
                    How to apply in your education
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                    To apply TILE in your education, four different types are
                    developed:
                    <ul>
                        <li>
                            Test run TILES - When we show (or ask) our students
                            what happens when we run (or execute) a piece of
                            programming code, we can, with a minor change of the
                            wording make a TILE out of the example. For example,
                            we can ask the students to test the program, or ask
                            them to assert the right output instead of asking
                            them to run the program.
                        </li>
                        <li>
                            Test cases TILES - If we ask students to test their
                            programs, they often only test happy path execution
                            and hence do not really get that awareness we want
                            about the importance of testing. A better way to
                            TILE exercises would be to add more concrete
                            examples of, or ideas about, possible test cases
                            that the student should use to check the workings of
                            their code. These example test cases should not just
                            include the happy path tests, but also make sure
                            that their code is challenged on some corner cases
                            and other exceptional cases.
                        </li>
                        <li>
                            Test message TILES - TILES of this type hide a
                            subliminal message about the importance of testing.
                        </li>
                        <li>
                            Test domain TILES - TILES of this type require a bit
                            more creativity than the previous ones. In some
                            programming exercises, we use examples from a
                            well-known domain. For example, in explanations of
                            Object-Oriented Programming one might use the
                            concept of shapes with classes such as Rectangle and
                            Circle as a domain. If the domain does not influence
                            the concepts we are teaching, then it can be
                            replaced with examples from the testing domain
                            directly.
                        </li>
                    </ul>
                </Typography>
                <Typography variant="h4" gutterBottom>
                    Available material
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                    <ul>
                        <li>
                            <a href="https://tile-repository.github.io">
                                A large number of TILED exercises has been
                                developed and is available in our TILE
                                repository
                            </a>
                        </li>
                        <li>
                            <a href="https://tile-repository.github.io/nifties/2022/nifty2022.html">
                                Example of a complex, project-like assignment
                                using TILE
                            </a>
                        </li>
                        <li>
                            <a href="https://qped.eu/tile_in_education.pdf">
                                More information about how to apply TILE in your
                                education
                            </a>
                        </li>
                    </ul>
                </Typography>
            </Container>

            <Footer />
        </ThemeProvider>
    );
}
